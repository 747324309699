import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import AuthApi from "./auth/AuthApi";
import Chatbot from "./Chatbot";
import CommunityPages from "./citrineHub/citrineComponents/Community/CommunityPage/CommunityPages";
import EdocsPage from "./citrineHub/citrineComponents/edocs/edocsPages/EdocsPage";
import SingleDoc from "./citrineHub/citrineComponents/edocs/edocsPages/SingleDoc";
import EshopPages from "./citrineHub/citrineComponents/eShop/EshopPage/EshopPages";
import EventsPage from "./citrineHub/citrineComponents/eventes/eventPages/EventsPage";
import PartnersPages from "./citrineHub/citrineComponents/Partners/PartnersPage/PartnersPages";
import PodcastPage from "./citrineHub/citrineComponents/Podcast/PodcastPages/PodcastPage";
import RecognitionPage from "./citrineHub/citrineComponents/Recognition/RecognitionPages/RecognitionPage";
import TrainingPages from './citrineHub/citrineComponents/Training/TrainingPage/TrainingPages';
import WebinarePage from "./citrineHub/citrineComponents/Webinars/webinarsPages/WebinarePage";
import CitrineHub from "./citrineHub/CitrineHub.jsx";
import About from "./components/About";
import Blog from "./components/Blog";
import Home from "./components/Home.jsx";
import Login from "./components/Login/Login.jsx";
import LoginRedirect from "./components/LoginRedirect";
import NotFound from "./components/NotFound";
import Pricing from "./components/pricing/Pricing";
import RequestDemo from "./components/ReaquestDemo/RequestDemo";
import Redirection from "./components/Redirection/Redirection";
import Register from "./components/Register/Register";
import SingleBlog from "./components/SingleBlog";
import { AuthContProvider } from "./context/AuthCont";
import AuthContext from "./context/AuthContext";
import SectionFooter from "./singleComponents/footer/SectionFooter";
// import Nav from "./singleComponents/nav/Nav";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthApi.isAuthenticated)

  return (
    <>
      <AuthContProvider>
       
        <AuthContext.Provider value={{
          isAuthenticated,
          setIsAuthenticated
        }}>
          <Chatbot />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/requestdemo" element={<RequestDemo />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about" element={<About />} />
            <Route path="/citrine-hub" element={<CitrineHub />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/edocs" element={<EdocsPage />} />
            <Route path="/singledoc" element={<SingleDoc />} />
            <Route path="/training" element={<TrainingPages />} />
            <Route path="/partners" element={<PartnersPages />} />
            <Route path="/eshop" element={<EshopPages />} />
            <Route path="/community" element={<CommunityPages />} />
            <Route path="/recognition" element={<RecognitionPage />} />
            <Route path="/podcast" element={<PodcastPage />} />
            <Route path="/webinars" element={<WebinarePage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/connect/google/redirect" element={<LoginRedirect />} />
            <Route path="/redirected" element={<Redirection />} />
            <Route path="/blog/:id" element={<SingleBlog />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthContext.Provider>
        <SectionFooter />
      </AuthContProvider>

    </>
  );
}

export default App;
