import React from 'react';
import { useNavigate } from "react-router-dom";
import './Eshop.css';
export default function Eshop({ eshop }) {
    const navigate = useNavigate();
    const imgvar = eshop.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

    return (
        <section className='citrine-eshop-container'>
            {/* Container */}
            <div className="container">

                {/* Row */}
                <div className="citrine-eshop">

                    <div className="citrine-eshop-contents">
                        <h2 className="citrine-eshop-contents-title">{eshop.attributes?.title}</h2>
                        <p>{eshop.attributes?.description}</p>
                        <button className="btn-citirnehub-eshop" onClick={() => navigate('/eshop')}>E-Shop</button>
                    </div>

                    <div className="citrine-eshop-image">
                        <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-eshop' />
                    </div>

                </div>{/* End of Row */}
            </div>{/* End of Container*/}
        </section>

    )
}
