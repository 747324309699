import React from 'react';
import Loading from '../components/loading/Loading';
import useFetch from '../hooks/useFetch';
import './Header.css';
import Heading from './heading/Heading';
export default function Header() {
    //const { estate, error, loading } = useFetch('https://www.citrine.cloud/api/header?populate=*')
    //const { estate, error, loading } = useFetch('http://localhost:1337/api/header?populate=*')
    const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/header?populate=*')

    if (loading) return <Loading />;
    if (error) return <p> Error :( </p>;

    return (
        <>
            <section className='hero'>
                <div className='container'>
                    <div className='row'>
                        <Heading subtitle={estate.attributes.subtitle} title={estate.attributes.title} />
                        <p className='row-p'>{estate.attributes.longsubtitle.substring(0, 200)}.</p>
                        <button className='btn-header'>REQUEST A DEMO</button>
                        {/* <button className='bnt-next-header'>
                                VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>
                            </button> */}

                    </div>
                </div>
            </section>
            <div className='margin'></div>
        </>

    )
}
