import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './CitrineBlog.css';

export default function CitrineBlog({ citrineBlog }) {
    const navigate = useNavigate();

    const imgvar = citrineBlog.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section className='citrine-blog-container'>
            {/* Container */}
            <div className="container">

                {/* Row */}
                <div className="citrine-blog">

                    <div className="citrine-blog-contents">
                        <h2 className="citrine-blog-contents-title">{citrineBlog.attributes?.title}</h2>
                        <p>{citrineBlog.attributes?.description}</p>
                        <button className="btn-citirnehub-blog" onClick={() => navigate('/blog')}>Citrine BlogPost</button>
                    </div>

                    <div className="citrine-blog-image">
                        <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-blogpost' />
                    </div>

                </div>{/* End of Row */}
            </div>{/* End of Container*/}
        </section>
    )
}
