import React from 'react'

export default function Heading({ subtitle, title }) {
    return (
        <>
            <div id='heading'>
                <h2 className='title-heading-about'>{title}</h2>
            </div>
        </>
    )
}
