import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Nav from '../../singleComponents/nav/Nav';
import './RequestDemo.css';
export default function RequestDemo() {
  const [details, setDetails] = useState({
    name: '',
    email: '',
    country: '',
    institution: '',
    accredited: '',
    organisationName: '',
    occupation: '',
    linkedin: '',
    purpose: ''
  })
  const [iterested, setIterested] = useState(null)
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const url = 'https://api.citrine.cloud/api/demos'


  const handleChange = (e) => {
    const { name, value } = e.target
    setDetails((prev) => {
      return { ...prev, [name]: value }
    })
  }

  const handleSubmt = async (e) => {
    e.preventDefault()

    axios.post(url,
      {
        "data": {
          name: details.name,
          email: details.email,
          country: details.country,
          institution: details.institution,
          accredited: details.accredited,
          organisationName: details.organisationName,
          occupation: details.occupation,
          linkedin: details.linkedin,
          iterested,
          purpose: details.purpose,

        }
      }
    )
      .then(response => {
        console.log(response);
      });






    setDetails({
      name: '',
      email: '',
      country: '',
      institution: '',
      accredited: '',
      organisationName: '',
      occupation: '',
      linkedin: '',
      purpose: ''
    })
  }
  const Request = [
    'Solution Obtention',
    'becoming a partner',
    'Becoming a consultant',
    'Discovering the solution',
    'Becoming a collaborator',
    "Schindler's List",
    'Other',
  ]
  const onHandleChange = (e, newValue) => {
    setIterested(newValue)
  }
  return (
    <>
      <Nav />
      <div className='container'>
        <div className="form-container">
          <form onSubmit={handleSubmt}>
            <Box display='flex' flexDirection={'column'} Width={900} alignItems='center'>
              <Typography variant="h3" component="h2" sx={{ fontSize: { xs: 30 }, mt: 5 }}>
                Request a demo
              </Typography>
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} value={details.name} name='name' label="Name:" variant="outlined" onChange={handleChange} />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 2, mb: 1 }} value={details.email} name='email' label="E-mail" variant="outlined" onChange={handleChange} />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} value={details.country} name='country' label="Country" variant="outlined" onChange={handleChange} />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} value={details.institution} name='institution' label="Institution/Establishment Name:" variant="outlined" onChange={handleChange} />

              <FormControl className='access' sx={{ marginLeft: { md: -60, lg: 0, xl: 0 } }}>
                <FormLabel id="demo-row-radio-buttons-group-label">Is your Institution Accredited:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name='accredited'
                  value={details.accredited}
                  onChange={handleChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="yes" />
                  <FormControlLabel value="no" control={<Radio />} label="no" />
                </RadioGroup>
              </FormControl>
              <TextField id="outlined-basic" sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} value={details.organisationName} name='organisationName' label="Your Accrediting Organisation Name:" variant="outlined" onChange={handleChange} />
              <TextField id="outlined-basic" sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} value={details.occupation} name='occupation' label="Occupation:" variant="outlined" onChange={handleChange} />
              <TextField id="outlined-basic" sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} value={details.linkedin} name='linkedin' label="Linkedin Profile:" variant="outlined" onChange={handleChange} />
              <Autocomplete
                onChange={onHandleChange}
                name='iterested'
                value={iterested}
                id="combo-box-demo"
                options={Request}
                sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }}
                renderInput={(params) => <TextField {...params} label="Interested in:" />}
                freeSolo
              />
              <input className='access please' value={details.purpose} name='purpose' onChange={handleChange} placeholder=" Purpose behind requesting Demo:" />
              <Box>

                {/* <TextareaAutosize
                  minRows={3}
                  maxRows={100}
                  aria-label="maximum height"
                  placeholder=" Purpose behind requesting Demo:"
                  name='purpose'
                  value={details.purpose}
                  style={{width:350, marginTop: 10, height: 200 }}
                  onChange={handleChange}
                /> */}
              </Box>
              <ButtonUnstyled type='submit'>submit</ButtonUnstyled>
            </Box>
          </form>
        </div>
      </div>
    </>
  )
}
