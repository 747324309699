import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
export default function LoginRedirect() {
    const navigate = useNavigate()
    const location = useLocation();
    const [text, setText] = useState('Loading...');

    useEffect(() => {
        // Successfully logged with the provider
        // Now logging with strapi by using the access_token (given by the provider) in props.location.search
        fetch(`https://api.citrine.cloud/api/auth/google/callback${location.search}`)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
                }
                return res;
            })
            .then(res => res.json())
            .then(res => {
                // Successfully logged with Strapi
                // Now saving the jwt to use it for future authenticated requests to Strapi
                localStorage.setItem('jwt', res.jwt);
                localStorage.setItem('username', res.user.username);
                setText('You have been successfully logged in. You will be redirected in a few seconds...');
                setTimeout(() => navigate('/'), 3000); // Redirect to homepage after 3 sec
            })
            .catch(err => {
                console.log(err);
                setText('An error occurred, please see the developer console.')
            });
    }, [location.search]);

    return (
        <>
            <p>{text}</p>
            <div>Loadin ....</div>
        </>
    )
}
