import { Typography } from '@mui/material';
import React from 'react';

export default function CommunityBoxs({ title, description, img }) {

    return (
        <div className='comunity-boxs'>
            <div className="chiledboxestext">
                <Typography variant='h4' component='h3' sx={{ mt: 5, mb: 5 }}>
                    {title}
                </Typography>

                <p>
                    {description}
                </p>
            </div>
            <img src={`https://api.citrine.cloud${img}`} alt="" />
        </div>
    )
}
