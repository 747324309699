import React from 'react'
import './about.css'
export default function NotFound() {
    return (
        <>
            <section className='hero-about'>
                <div className='container'>
                    <div className='row-about'>
                        <p>HOME/ABOUT</p>
                        <h1 className='about-title'>About Us</h1>
                    </div>
                </div>
            </section>
            <div className='margin'></div>


            Page NotFound :(
        </>
    )
}
