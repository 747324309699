import React from 'react';
import { useNavigate } from "react-router-dom";
import './Recognition.css';

export default function Recognition({ recognition }) {
    const navigate = useNavigate();

    const imgvar = recognition.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

    return (
        <section>
            {/* Container */}
            <div className="container">

                {/* Row */}
                <div className="citrine-recognition">
                    <div className="citrine-recognition-image">
                        <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-recognition' />
                    </div>
                    <div className="citrine-recognition-contents">
                        <h2 className="citrine-recognition-contents-title">{recognition.attributes?.title}</h2>
                        <p>{recognition.attributes?.description}</p>
                        {/* <button className="btn-citirnehub-recognition" onClick={() => navigate('/recognition')}>Recognition</button> */}
                    </div>

                </div>{/* End of Row */}
            </div>{/* End of Container*/}
        </section>
    )
}
