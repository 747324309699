import React from 'react';
import { useNavigate } from "react-router-dom";

import './Events.css';

export default function Events({ estate }) {
    const navigate = useNavigate();

    const imgvar = estate.attributes?.image?.data?.map((item) => item.attributes?.formats?.medium?.url);
    return (
        <section>
            {/* Container */}
            <div className="container">

                {/* Row */}
                <div className="citrine-events">
                    <div className="citrine-events-image">
                        <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-events' />
                    </div>
                    <div className="citrine-events-contents">
                        <h2 className="citrine-events-contents-title">{estate.attributes?.title}</h2>
                        <p>{estate.attributes?.description}</p>
                        <button className="btn-citirnehub" onClick={() => navigate('/events')}>Check Out our Upcoming Events</button>
                    </div>

                </div>{/* End of Row */}
            </div>{/* End of Container*/}
        </section>


    )
}
