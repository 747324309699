import React from 'react';
import { useNavigate } from "react-router-dom";
import './podcast.css';

export default function Podcast({ podcast }) {
  const navigate = useNavigate();

  const imgvar = podcast.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

  return (
    <section className='citrine-podcast-container'>
      {/* Container */}
      <div className="container">

        {/* Row */}
        <div className="citrine-podcast">

          <div className="citrine-podcast-contents">
            <h2 className="citrine-podcast-contents-title">{podcast.attributes?.title}</h2>
            <p>{podcast.attributes?.description}</p>
            {/* <button className="btn-citirnehub-podcast" onClick={() => navigate('/podcast')}>Check Out Our Podcast</button> */}
          </div>

          <div className="citrine-podcast-image">
            <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-partners' />
          </div>

        </div>{/* End of Row */}
      </div>{/* End of Container*/}
    </section>
  )
}
