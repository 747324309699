import React from 'react'
import '../footer.css'
export default function FooterNewsLetters() {
    return (
        <section className='newletter'>
            <div className='container flexSB'>
                <div className='left row'>
                    <h1>Newsletter - Stay tuned and get the latest update</h1>
                    <span>Far far away, behind the word mountains</span>
                </div>
                <div className='right row'>
                    <input type='text' placeholder='Enter email address' />
                    <i className='fa fa-paper-plane'></i>
                </div>
            </div>
        </section>
    )
}
