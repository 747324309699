import React, { useEffect } from 'react';
import useFetch from '../../../../hooks/useFetch';
import Nav from '../../../../singleComponents/nav/Nav';
import CommunityBoxs from './CommunityBoxs';
import CommunityService from './CommunityService';

export default function CommunityPages() {
  const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/community-services')
  const { estate: feedbox } = useFetch('https://api.citrine.cloud/api/feedbox?populate=*')
  const { estate: bosuggestonbox } = useFetch('https://api.citrine.cloud/api/suggeston-box?populate=*')
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  if (loading) return 'loading...';
  if (error) return <p> Error :( </p>;

  const urlfedbox = feedbox?.attributes?.image?.data?.map((img) => { return img.attributes.url });
  const urlbosuggestonbox = bosuggestonbox?.attributes?.image?.data?.map((img) => { return img.attributes.url });
  return (
    <>
      <Nav />
      <div className="communitypagestyle">
        <div className="container">
          <CommunityBoxs title={feedbox?.attributes?.title} description={feedbox?.attributes?.description} img={urlfedbox} />
          <CommunityBoxs title={bosuggestonbox?.attributes?.title} description={bosuggestonbox?.attributes?.description} img={urlbosuggestonbox} />
          <CommunityService estate={estate} />
        </div>
      </div>
    </>
  )
}




