import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Person4Icon from '@mui/icons-material/Person4';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import useFetch from '../hooks/useFetch';
import Nav from '../singleComponents/nav/Nav';
import './blog.css';
export default function Blog() {
  //const URL = 'http://localhost:1337/api/contents?populate=*'



  //const { estate, error, loading } = useFetch('https://www.citrine.cloud/api/contents?populate=*')
  //const { estate, error, loading } = useFetch('http://localhost:1337/api/contents?populate=*')
  const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/contents?populate=*')


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;


  return (
    <>
      <Nav />
      <section className='blog'>
        <div className='container'>
          <div className='grid2'>
            {estate?.map((house) => (
              <div className='items shadow'>
                <div className='img'>
                  {house.attributes.image.data?.map((pic) => (
                    <img src={`https://api.citrine.cloud${pic.attributes.url}`} alt="img" className="card-img-top" />
                  ))}
                </div>
                <div className='text'>
                  <div className='admin flexSB'>
                    <span>
                      <Person4Icon className='iconss' />
                      <label>ADMIN</label>
                    </span>
                    <span>
                      <CalendarMonthIcon className='iconss' />
                      <label>{house.attributes.createdAt.split('').splice(0, 10)}</label>
                    </span>
                    <span>
                      <AccessTimeFilledIcon className='iconss' />
                      <label>{house.attributes.createdAt.split('').splice(11, 8)}</label>
                    </span>
                  </div>
                  <h1>{house.attributes.title}</h1>
                  <p>{house.attributes.description.substring(0, 100)}</p>
                  <button className="btn-hblog"><Link to={`/blog/${house.id}`}>Read More <ArrowForwardIcon /></Link></button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
