import React from 'react'
import useFetch from '../../hooks/useFetch.js'
import './AboutCard.css'
import Mainthree from './Mainthree.jsx'
export default function AboutCard() {
    // const { estate, error, loading } = useFetch('https://www.citrine.cloud/api/aboutsection?populate=*')
    //const { estate, error, loading } = useFetch('http://localhost:1337/api/aboutsection?populate=*')
    const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/aboutsection?populate=*')

    if (loading) return <p> Loading... </p>;
    if (error) return <p> Error :( </p>;




    return (
        <div>
            <>
                <h2 className='about-card-title'>{estate.attributes.title}</h2>
                <section className='aboutHome'>
                    <div className='container flexSB'>
                        <div className='left row'>
                            <img src={`https://api.citrine.cloud${estate.attributes.image.data?.map((i) => i.attributes.url)}`} alt='' />
                        </div>
                        <div className='right row'>
                            <Mainthree />
                        </div>
                    </div>
                </section>
                {/* <Awrapper /> */}
            </>
        </div>
    )
}
