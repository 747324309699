import { useContext , createContext, useEffect, useState  } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { URL_LOGIN, URL_REGISTER } from '../../src/config.js';
const AuthCont = createContext()

export const AuthContProvider = ({children}) =>{
const [user,setUser] = useState({})

function authenticate(credentials) {
    return axios.post(URL_LOGIN, credentials)
        .then(res => res.data)
        .then(data => {
            window.localStorage.setItem("authToken", data.jwt)
            window.localStorage.setItem("username", data.user.username)
            axios.defaults.headers["Authorization"] = "Bearer " + data.jwt
            console.log(isAuthenticated());
            setUser(data)
            
        })
    }

function authenticateRegister({ username, email, password }) {
            return axios.post(URL_REGISTER, { username, email, password })
        
        
        }
 function isAuthenticated() {
            const token = window.localStorage.getItem("authToken")
            if (token) {
                const { exp } = jwtDecode(token)
                if (exp * 1000 > new Date().getTime()) {
                    return true
                }
                return false
            }
            return false
        }
function logout() {
            window.localStorage.removeItem('authToken')
            window.localStorage.removeItem('username')
            delete axios.defaults.headers['Authorization']
        }


 
    return(
        <AuthCont.Provider value={{authenticate,authenticateRegister,isAuthenticated,logout,user}}>
            {children}
        </AuthCont.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthCont)
}