import React from 'react';
import { useNavigate } from "react-router-dom";
import './Edocs.css';
export default function Edocs({ edocs }) {
    const navigate = useNavigate();
    const imgvar = edocs.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

    return (

        <section className='citrine-edocs-container'>
            {/* Container */}
            <div className="container">

                {/* Row */}
                <div className="citrine-edocs">

                    <div className="citrine-edocs-contents">
                        <h2 className="citrine-edocs-contents-title">{edocs.attributes?.title}</h2>
                        <p>{edocs.attributes?.description}</p>
                        <button className="btn-citirnehub" onClick={() => navigate('/edocs')}>Check Out Our Documentations</button>
                    </div>


                    <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-edocs' />


                </div>{/* End of Row */}
            </div>{/* End of Container*/}
        </section>

    )
}
