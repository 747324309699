import React from 'react';
import { useNavigate } from "react-router-dom";
import './Community.css';
export default function Community({ community }) {
    const navigate = useNavigate();
    const imgvar = community.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

    return (
        <section>
            {/* Container */}
            <div className="container">
                {/* Row */}
                <div className="citrine-community">
                    <div className="citrine-community-image">
                        <img src={`https://api.citrine.cloud${imgvar}`} className='image-community' alt="About Us" />
                    </div>
                    <div className="citrine-community-contents">
                        <h2 className="citrine-community-contents-title">{community.attributes?.title}</h2>
                        <p>{community.attributes?.description}</p>
                        <button className="btn-citirnehub-community" onClick={() => navigate('/community')}>Our Community</button>
                    </div>
                </div>{/* End of Row */}
            </div>{/* End of Container*/}
        </section>

    )
}
