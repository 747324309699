import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import React from 'react';
export default function CommunityService({ estate }) {
   
    return (
        <>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    {estate.map((item) => {
                        return (
                            <Grid item xs={2} sm={4} md={4} >

                                <Card sx={{ minWidth: 275, mb: 1.5 }}>
                                    <CardContent>

                                        <Typography variant="h5" sx={{ mb: 1.5 }}>
                                            {item.attributes.title}
                                        </Typography>

                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {item.attributes.description}
                                        </Typography>

                                    </CardContent>
                                    <CardActions sx={{ mt: -1.5 }}>
                                        <Button size="small">Learn More</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}


                </Grid>
            </Box>
        </>
    )
}
