import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Loading from '../../../../components/loading/Loading.jsx';
import useFetch from '../../../../hooks/useFetch.js';

import Nav from '../../../../singleComponents/nav/Nav';
import './EventsPage.css';

export default function EventsPage() {
  const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/upcoming-event?populate=*')
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) 
  
  if (loading) return <Loading />;
  if (error) return <p> Error :( </p>;
  
  console.log(estate.attributes.date.split('T'));
  return (
    <>
      <Nav />
      <div className="events">
        <img src="images/blog/conferance.jpg" alt="" />
        <div className="overlay">
          <div className="container">
            <div className="modalform">
              <div className="header-part">
                <h2 className='logo-header-part-title'>{estate.attributes.title} </h2>
                <img src="images/blog/conferance.jpg" alt="" />
                <div className="conferancegroup">
                  <p><i className="fa-solid fa-map-location-dot" ></i> {estate.attributes.location}</p>
                  <p><i className="fa-solid fa-calendar-days " ></i> {estate.attributes.date.split('T')[0]}</p>
                  <p><i className="fa-solid fa-clock " ></i> {estate.attributes.date.split('T')[1].substring(0, 5)}</p>
                </div>
                  <div className="decepage">
                    <p>{estate.attributes.description}</p>
                  </div>
                <button onClick={() => navigate('/requestdemo')} className='RNEbtn'>REGISTER NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
