import React, { useEffect } from 'react'
import useFetch from '../../../../hooks/useFetch'
import Nav from '../../../../singleComponents/nav/Nav'
import Eshop from '../Eshop'

export default function EshopPages() {
  const { estate: eshop } = useFetch('https://api.citrine.cloud/api/e-shop?populate=*')
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Nav />
      {/* <SoonPage /> */}
      <Eshop eshop={eshop} />

    </>
  )
}
