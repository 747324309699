import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Nav from '../../../../singleComponents/nav/Nav'
import './EdocsPage.css'
export default function EdocsPage() {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Nav />
      <div className="container">

        <div className="contntent-edocs">
          <div className="firstpart">
            <h1>E-DOCS</h1>
            <p>Citrine E-docs is your Best Library to Learn more about the Solution through the different available documentations ..
              If you want to see any further documentation Type on our library, Please share it with us through the Recommendations Box! </p>
            <button className='btn-singledoc' onClick={() => navigate('/singledoc')}>Check Out our Documentations</button>
          </div>
          <img src="images/blog/wesley-tingey-snNHKZ-mGfE-unsplash.jpg" alt="" />
        </div>
      </div>
    </>
  )
}
