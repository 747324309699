import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import useFetch from '../../hooks/useFetch';

export default function AboutSectionTwo({ }) {
    const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/about-section-tow-carousels?populate=*')
    const { estate: test } = useFetch('https://api.citrine.cloud/api/about-section-two')

    if (loading) return <p> Loading... </p>;
    if (error) return <p> Error :( </p>;

    return (
        <div className="section-two-container">
            <div className="container">
                <div className="content-about-section-tow">
                    <h2>{test?.attributes?.title}</h2>
                    <p>{test?.attributes?.description}</p>
                </div>
            </div>

            <Carousel>
                {!loading ? estate?.map((item) => {
                    return (
                        <Carousel.Item>
                            <div style={{
                                width: '1500px',
                                height: '500px',
                                backgroundPosition: 'center',
                                backgroundImage: `url(${'https://api.citrine.cloud' + item.attributes.image.data.map((it) => it.attributes.url)})`,

                            }}>

                            </div>

                            <Carousel.Caption>
                                <div className="textContent bg-dark bg-opacity-25">
                                    <h3 className='pt-5' style={{ marginTop: '-300px', fontSize: '40px', marginBottom: '30px' }}>{item.attributes.title}</h3>
                                    <p className='pb-5'>{item.attributes.description}</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                }) : (
                    <p>loading...</p>
                )}
            </Carousel>
        </div>
    )
}
