import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from '../../context/AuthCont';
import AuthContext from "../../context/AuthContext";
import Nav from '../../singleComponents/nav/Nav';
import './Login.css';
export default function Login() {
  const navigate = useNavigate()
  const [credentials, setCredntials] = useState({
    identifier: "",
    password: "",
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const { setIsAuthenticated } = useContext(AuthContext)
  const { authenticate } = UserAuth()




  const handleChange = (e) => {
    console.log(e.target);
    const { value, name } = e.target
    console.log(value, name);
    setCredntials({
      ...credentials,
      [name]: value
    })
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authenticate(credentials)
      setIsAuthenticated(true)
      navigate('/about')
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <>
      <Nav />
      <div className='login'>
        <h1> LOGIN</h1>
        {/* <div className='container-login'>
          <div className='top-login'>
            <i class='fab fa-google'></i>
            <i class='fab fa-facebook-square'></i>
            <i class='fab fa-linkedin'></i>
            <i class='fab fa-twitter-square'></i>
            <i class='fab fa-apple'></i>
          </div>
          <p className='divider'><span className='spain'>Or</span></p>
          <form onSubmit={handleSubmit}>
            <label>E-mail</label>
            <input type='email' placeholder='Enter your email' name="identifier" onChange={handleChange} />
            <label>Password</label>
            <input type='password' placeholder='Enter your password' name="password" onChange={handleChange} />
            <div className='remember'>
            </div>
            <button type="submit" className='btn-login'>Log In</button>
          </form>
          <div className='bottom'>
            <p>Forget your password?</p>
            <a href='/'>Reset Password</a>
          </div>
          <p className='create' onClick={() => navigate('/register')}>Sign Up</p>
        </div> */}
        <form onSubmit={handleSubmit}>
          <Box display='flex' flexDirection={'column'} Width={900} alignItems='center'>


            <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 2, mb: 1 }} name='identifier' label="E-mail" variant="outlined" onChange={handleChange} />
            <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} name='password' type='password' label="Password:" variant="outlined" onChange={handleChange} />

          </Box>
          <p className='create' >If you dont have an account <span onClick={() => navigate('/register')}>Register</span></p>
          <button type='submit' className='btn-login'>Login</button>
          <button className='btn-login' onClick={() =>
            (window.location.href = 'https://api.citrine.cloud/api/connect/google')
          }>Login via Google</button>
        </form>
        
      </div>
    </>
  )
}
