import React, { useEffect } from 'react'
import useFetch from '../../../../hooks/useFetch'
import Nav from '../../../../singleComponents/nav/Nav'
import Podcast from '../Podcast'

export default function PodcastPage() {
  const { estate: podcast } = useFetch('https://api.citrine.cloud/api/podcast?populate=*')
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Nav />
      {/* <SoonPage /> */}
      <Podcast podcast={podcast} />

    </>
  )
}
