import React from 'react'

export default function AboutSectionFour({ sectionFour }) {
  const uri = sectionFour.attributes?.image?.data?.map((img)=>{
    return  img.attributes.url
  });
  return (
    <div className='container'>
      <div className="aboutonecontent">
        <div className="about-on-content">
          <h2 className="about-title-one">{sectionFour.attributes?.title}</h2>
          <p className='about-decription-one'>{sectionFour.attributes?.description}</p>
        </div>
        <div className="about-one-img">
          <img src={`https://api.citrine.cloud${uri}`} alt="" />
        </div>
      </div>
    </div>
  )
}
