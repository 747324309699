import React from 'react';
import useFetch from '../../hooks/useFetch';
import Loading from '../loading/Loading';

export default function ProviderLeft() {
    const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/provide-rights')
    if (loading) return <Loading />;
    if (error) return <p> Error :( </p>;
    return (
        <div className="provide-left">
            {estate.map((item) => {
                return (
                    <div key={item.id}>

                        <span>{item.attributes.num}</span>
                        <div className="span-content left">

                            <h3>{item.attributes.title}</h3>
                            <p>{item.attributes.description}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
