import React from 'react';
import { useNavigate } from "react-router-dom";
import './provide.css';
import ProviderLeft from './ProviderLeft';
import ProviderRight from './ProviderRight';
export default function Provide() {
    const navigate = useNavigate()
    //onsole.log(estate.map((item) => item.attributes.description));

    return (
        <div className='container'>
            <h2 className='provide-title'>WE PROVIDE</h2>
            <div className="provide-content">
                <ProviderRight />
              
                <ProviderLeft />
            </div>
            <button className='provide-btn' onClick={() => navigate('/requestdemo')}>Request a Demo</button>
        </div>
    )
}
