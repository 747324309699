import React, { useEffect } from 'react'
import useFetch from '../../../../hooks/useFetch'
import Nav from '../../../../singleComponents/nav/Nav'
import Webinairs from '../Webinairs'

export default function WebinarePage() {
  const { estate: webinairs } = useFetch('https://api.citrine.cloud/api/webinar?populate=*')
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Nav />
      {/* <SoonPage /> */}
      <Webinairs webinairs={webinairs} />

    </>
  )
}
