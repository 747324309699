import CloudPdfViewer from '@cloudpdf/viewer';
import React, { useEffect, useRef } from 'react';
import Nav from '../../../../singleComponents/nav/Nav.jsx';
export default function SingleDoc() {
    const viewer = useRef(null);
    useEffect(() => {
        CloudPdfViewer(
            {
                documentId: "3d529b55-566d-46de-aaa6-82b4b5cbc654",
                darkMode: true,
                Height: 1000
            },
            viewer.current
        ).then((instance) => { });
    }, []);
    return (
        <>
            <Nav />
            <div className='container'>
                <h1 className='citrine-flyer'>CITRINE FLYER</h1>
                <div className="viewer" ref={viewer}></div>

            </div>
        </>
    )
}
