import React from 'react';
import { useNavigate } from "react-router-dom";
import './Training.css';
export default function Training({ training }) {
    const navigate = useNavigate();
    const imgvar = training.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

    return (
        <section>
            {/* Container */}
            <div className="container">

                {/* Row */}
                <div className="citrine-training">
                    <div className="citrine-training-image">
                        <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-training' />
                    </div>
                    <div className="citrine-training-contents">
                        <h2 className="citrine-training-contents-title">{training.attributes?.title}</h2>
                        <p>{training.attributes?.description}</p>
                        {/* <button disabled className="btn-citirnehub" >Check Out Our Trainings and Certifications </button> */}
                        
                    </div>

                </div>{/* End of Row */}
            </div>{/* End of Container*/}
        </section>
    )
}
