import React, { useEffect } from 'react'
import AboutCard from '../singleComponents/aboutCard/AboutCard'
import Header from '../singleComponents/Header'
import Head from '../singleComponents/headLogo/HeadLogo.jsx'
import Nav from '../singleComponents/nav/Nav.jsx'
import Provide from './Provide/Provide'
export default function Home() {
    
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Head />
      <Nav />
      <Header />
      <AboutCard />
      {/* <Hblog /> */}
      <Provide />
      {/* <Module /> */}
      
    </>
  )
}
