import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';
import FooterNewsLetters from './footerNews/FooterNewsLetters.js';
export default function SectionFooter() {
    return (
        <>
            <FooterNewsLetters />
            <footer className='dflex'>
                <div className='container padding'>
                    <div className='box logo'>
                        <h1>CITRINE</h1>
                        <span></span>
                        <p className='footer-para'>Your Accreditation Governance Expert! Your effective partner in Academic Quality Assurance.</p>


                        <i className='fab fa-twitter icon'></i>

                        <i className='fab fa-linkedin icon'></i>


                    </div>
                    <div className="links-footer">
                        <div className='box link'>
                            <h3>Explore</h3>
                            <ul>
                                <li><Link to='/'>Home</Link></li>
                                <li><Link to='/about'>About Us</Link></li>
                                <li><Link to='/citrine-hub'>Citrine Hub</Link></li>
                                <li><Link to='/login'>Login</Link></li>
                                <li><Link to='/register'>Register</Link></li>
                                <li><Link to='/webinars'>Webinairs</Link></li>
                                <li><Link to='/podcast'>Podcast</Link></li>
                            </ul>
                        </div>
                        <div className='box link'>
                            <h3>Quick Links</h3>
                            <ul>
                                <li><Link to='/events'>Events</Link></li>
                                <li><Link to='/training'>Training</Link></li>
                                <li><Link to='/edocs'>E-Docs</Link></li>
                                <li><Link to='/community'>Comunity</Link></li>
                                <li><Link to='/eshop'>E-Shop</Link></li>
                                <li><Link to='/blog'>Blog</Link></li>
                                <li><Link to='/partners'>Partners</Link></li>

                            </ul>
                        </div>
                    </div>
                    {/* <div className='box'>
                        <h3>Recent Post</h3>
                        {blog.slice(0, 3).map((val) => (
                            <div className='items flexSB' key={val.id}>
                                <div className='img'>
                                    <img src={val.cover} alt='' />
                                </div>
                                <div className='text'>
                                    <span>
                                        <i className='fa fa-calendar-alt'></i>
                                        <label htmlFor=''>{val.date}</label>
                                    </span>
                                    <span>
                                        <i className='fa fa-user'></i>
                                        <label htmlFor=''>{val.type}</label>
                                    </span>
                                    <h4>{val.title.slice(0, 40)}...</h4>
                                </div>
                            </div>
                        ))}
                    </div> */}
                    <div className="footer-last-section">
                        <div className='box last'>
                            <h3>Contact Information</h3>
                            <ul>
                                <li>
                                    <i className='fa fa-map'></i>
                                    Tunisia - Carnelian Digital Innovation
                                    Rue du Lac-Imm.Hajer
                                    1053 Les berges du Lac,Tunis
                                </li>
                                <li>
                                    <i className='fa fa-phone-alt'></i>
                                    +216 71 960 504
                                </li>
                                <li>
                                    <i className='fa fa-paper-plane'></i>
                                    contact@carnelian.cloud
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='legal'>
                <p>
                    Copyright ©2022 All rights reserved | This template is made with <i className='fa fa-heart'></i> by CITRINE
                </p>
            </div>
        </>
    )
}
