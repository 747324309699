import React from 'react'
import CitrineHubHeader from '../../citrineHub/citrineHubHeader/CitrineHubHeader'
import Nav from '../../singleComponents/nav/Nav'
import FormPricing from './FormPricing.jsx'
export default function Pricing() {
  return (
    <>
      {/* <CitrineHubHeader title='Pricing' /> */}
      <Nav />
      <FormPricing />
    </>
  )
}
