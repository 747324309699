import React from 'react'
import { Link } from 'react-router-dom'
import './NavLinks.css'
export default function NavLinks() {
    return (
        <div className='global-links'>
            <div className="all-links">

                <div className="links">
                    <Link className='moved' to='/events'><span className='fas'>&#xf073; </span> Events & Conferences</Link>
                    <Link className='moved' to='/training'><span className='fas'>&#xf501; </span> Trainings & Certifications</Link>
                    <Link className='moved' to='/edocs'><span className='fas'>&#xf02d; </span> E-Docs</Link>
                    <Link className='moved' to='/community'><span className='fas'>&#xf0c0; </span> Our Community</Link>
                </div>

                <div className="links">
                    <Link className='moved' to='/eshop'><span className='fas'>&#xf291;</span> Online Shop</Link>
                    <Link className='moved' to='/blog'><span className='fas'>&#xf518;</span> Blog</Link>
                    <Link className='moved' to='/partners'><span className='fas'>&#xf2b5; </span> Our Partners & Consultants</Link>
                </div>

                <div className="links">
                    <Link className='moved' to='/webinars'><span className='fas'>&#xf0e8;</span> Our Webinars</Link>
                    <Link className='moved' to='/podcast'><span className='fas'>&#xf3c9; </span> Our Podcast</Link>
                    <Link className='moved' to='/recognition'><span className='fas'>&#xf075;</span> Recognition</Link>
                </div>

            </div>
        </div>
    )
}
