import React from 'react'
import '../loading/loading.css'
export default function Loading() {
    return (
        <div className="Loading">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
