import React from 'react';
import { useNavigate } from "react-router-dom";
import './Webinairs.css';

export default function Webinairs({ webinairs }) {
  const navigate = useNavigate();

  const imgvar = webinairs.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

  return (
    <section className='citrine-webinairs-container'>
      {/* Container */}
      <div className="container">

        {/* Row */}
        <div className="citrine-webinairs">

          <div className="citrine-webinairs-contents">
            <h2 className="citrine-webinairs-contents-title">{webinairs.attributes?.title}</h2>
            <p>{webinairs.attributes?.description}</p>
            {/* <button className="btn-citirnehub-webinairs" onClick={() => navigate('/webinars')}>Check Out Our Webinars</button> */}
          </div>

          <div className="citrine-webinairs-image">
            <div className="yellopart"></div>
            <div className="image-content">
              <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-webinairs' />
            </div>
          </div>

        </div>{/* End of Row */}
      </div>{/* End of Container*/}
    </section>
  )
}
