import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import useFetch from '../hooks/useFetch';
import Nav from '../singleComponents/nav/Nav';
import './singleblog.css';
export default function SingleBlog() {
    const { id } = useParams()
    const navigate = useNavigate()
    const url = 'https://api.citrine.cloud/api/contents/'
    const { estate, error, loading } = useFetch(`${url}${id}?populate=*`)

    if (loading) return <p> Loading... </p>;
    if (error) return <p> Error :( </p>;
    console.log(estate.attributes.image.data.map((img) => img.attributes.url));
    console.log(estate.attributes.title);
    console.log(url);
    const goback = () => {
        navigate('/blog')
    }
    return (
        <>
            <Nav />

            <div className='container'>
                <div className="singleProdect">
                    <div className="imagesp">
                        <img className='singleimage' src={`https://api.citrine.cloud` + estate.attributes.image.data.map((img) => img.attributes.url)} alt="" />
                    </div>
                    <div className="singleProductContent">
                        <h1>{estate.attributes.title}</h1>
                        <p>{estate.attributes.description}</p>
                        <div className="timesingleblog">
                            <span>
                                <CalendarMonthIcon className='iconsss'/>
                                {estate.attributes.createdAt.split('').splice(0, 10)}
                            </span>
                            <span>
                                <AccessTimeFilledIcon className='iconsss' />
                                {estate.attributes.createdAt.split('').splice(11, 8)}
                            </span>
                           
                        </div>

                        <span className='btngoback' onClick={goback}>
                                <ArrowForwardIcon />
                                Go Back
                            </span>
                    </div>
                </div>
            </div>
        </>
    )
}
