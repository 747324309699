import React, { useEffect } from 'react';
import useFetch from '../hooks/useFetch';
import Nav from '../singleComponents/nav/Nav';
import './about.css';
import AboutSectionFour from './aboutPages/AboutSectionFour';
import AboutSectionOne from './aboutPages/AboutSectionOne';
import AboutSectionThree from './aboutPages/AboutSectionThree';
import AboutSectionTwo from './aboutPages/AboutSectionTwo';
export default function About() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //const { estate, error, loading } = useFetch('https://www.citrine.cloud/api/abouts?populate=*')
    //const { estate, error, loading } = useFetch('http://citrine.cloud:1337/api/abouts?populate=*')
    // if (loading) return <Loading />;;
    // if (error) return <p> Error :( </p>;
    const { estate: sectionOne } = useFetch('https://api.citrine.cloud/api/about-section-one?populate=*')
    const { estate: sectionThree } = useFetch('https://api.citrine.cloud/api/about-section-three?populate=*')
    const { estate: sectionFour } = useFetch('https://api.citrine.cloud/api/about-section-four?populate=*')
    const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/about-section-tow-carousels?populate=*')


    if (loading) return <div>loading</div>;
    if (error) return <p> Error :( </p>;
    return (
        <>
            <Nav />
            <AboutSectionOne sectionOne={sectionOne} />
            <AboutSectionThree sectionThree={sectionThree} />
            <AboutSectionTwo estate={estate} />
            <AboutSectionFour sectionFour={sectionFour} />

        </>

    )
}