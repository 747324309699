import React, { useEffect } from 'react'
import useFetch from '../../../../hooks/useFetch'
import Nav from '../../../../singleComponents/nav/Nav'
import './PartnersPage.css'
export default function PartnersPages() {
  const { estate } = useFetch('https://api.citrine.cloud/api/bconsultant?populate=*')
  const { estate: partner } = useFetch('https://api.citrine.cloud/api/bpartner?populate=*')
  const { estate: find } = useFetch('https://api.citrine.cloud/api/find-partner?populate=*')

  const img = estate?.attributes?.image.data.map((img) => img.attributes.url)
  const imgpartner = partner?.attributes?.image.data.map((img) => img.attributes.url)
  const imgfind = find?.attributes?.image.data.map((img) => img.attributes.url)
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Nav />
      <div className="container">
        <div className="PartenersContent">
          <img className='imgshow' src={`https://api.citrine.cloud${img}`} alt="" />
          <div className="left-part-partners">
            <h2>{estate?.attributes?.title}</h2>
            <p>
              {estate?.attributes?.description}
            </p>
            {/* <button className='btn-constaltent'>Become a Consultant</button> */}
          </div>
          <img className='imghide' src={`https://api.citrine.cloud${img}`} alt="" />
        </div>


        <div className="PartenersContent">
          <img src={`https://api.citrine.cloud${imgpartner}`} alt="" />

          <div className="left-part-partners">
            <h2>{partner?.attributes?.title}</h2>
            <p>{partner?.attributes?.description}
            </p>
            {/* <button className='btn-constaltent'>Become a Partner</button> */}
          </div>
        </div>

        <div className="PartenersContent">
          <img className='imgshow' src={`https://api.citrine.cloud${imgfind}`} alt="" />
          <div className="left-part-partners">
            <h2>{find?.attributes?.title}</h2>
            <p>
              {find?.attributes?.description}
            </p>
          </div>
          <img className='imghide' src={`https://api.citrine.cloud${imgfind}`} alt="" />
        </div>
      </div>
    </>
  )
}
