import React, { useContext, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AuthApi from '../../auth/AuthApi.js';
import { UserAuth } from '../../context/AuthCont.js';
import AuthContext from "../../context/AuthContext";
import './Nav.css';
import NavLinks from './NavLinks';
export default function Nav() {
    const [click, setClick] = useState(false)
    const [navDropDown, setNavDropDown] = useState(false)

    const username = window.localStorage.getItem("username")
  
    const navigate = useNavigate()

    const handleClick = () => {
        setNavDropDown(!navDropDown)
    }
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)
    
    const handleLogout = () => {
        AuthApi.logout()
        window.location = '/login'
        setIsAuthenticated(false)
    }

   
    return (
        <>
            <header>
                <nav className='flexSB'>
                    <ul className={click ? "mobile-nav" : "flexSB "}>
                        <li>
                            <Link className='a-padding' to='/'>Home</Link>
                        </li>
                        <li>
                            <Link className='a-padding' to='/about'>About Us</Link>
                        </li>

                        <li className='dropdown' onMouseEnter={() => setNavDropDown(true)} onMouseLeave={() => setNavDropDown(false)} >
                            <Link className='a-padding' to='/citrine-hub' onClick={handleClick}>Citrine Hub <span className='fas'>&#xf0d8;</span></Link>
                            <div className={navDropDown ? "dropdown-content" : 'removed'}>
                                {navDropDown ? (<NavLinks />) : null}
                            </div>
                        </li>

                        {/* <li>
                            <Link to='/pricing'>Pricing</Link>
                        </li> */}

                        {username ? (<li>
                            <Link className='a-padding' onClick={handleLogout}>{username}</Link>
                        </li>) : (<li>
                            <Link className='a-padding' to='/login'>Login</Link>
                        </li>)}




                    </ul>
                    <div className='start'>
                        <div className='button'>CITRINE</div>
                    </div>
                    <button className='toggle' onClick={() => setClick(!click)}>
                        {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
                    </button>
                </nav>
            </header>
        </>

    )
}
