import React from 'react';
import './partners.css';
import { useNavigate } from "react-router-dom";
export default function Partners({ partners }) {
  const navigate = useNavigate()
  const imgvar = partners.attributes?.image?.data?.map((item) => item.attributes?.formats?.large?.url);

  return (
    <section>
      {/* Container */}
      <div className="container">

        {/* Row */}
        <div className="citrine-partners">
          <div className="citrine-partners-image">
            <img src={`https://api.citrine.cloud${imgvar}`} alt="About Us" className='image-partners' />
          </div>
          <div className="citrine-partners-contents">
            <h2 className="citrine-partners-contents-title">{partners.attributes?.title}</h2>
            <p>{partners.attributes?.description}</p>
            <button className="btn-citirnehub-partners" onClick={()=>navigate('/partners')}>Check Out Our partners</button>
          </div>

        </div>{/* End of Row */}
      </div>{/* End of Container*/}
    </section>
  )
}
