import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { UserAuth } from '../../context/AuthCont.js';
import Nav from '../../singleComponents/nav/Nav';
import './Register.css';


export default function Register() {
  const navigate = useNavigate()
  const { authenticateRegister } = UserAuth()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleRegister = async (e) => {
    e.preventDefault();
    console.log({ username, email, password });
    try {
      await authenticateRegister({ username, email, password })
      window.location = '/login'
    } catch (error) {
      console.log(error);
    }

  }
  // const handleChange = (e) => {
  //   console.log(e.target);
  //   const { value, name } = e.target
  //   console.log(value, name);
  //   setCredntials({
  //     ...credentials,
  //     [name]: value
  //   })
  //   console.log(JSON.stringify(credentials));
  // }


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await AuthApi.authenticateRegister(credentials)
  //     alert('check your email')
  //     window.location.href = '/login'
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // const logout = (e) => {
  //   e.preventDefault();
  //   localStorage.removeItem('jwt');
  //   localStorage.removeItem('username');
  //   setIsLogged(false);
  //   navigate('/login')
  // };

  return (
    <>
      <Nav />
      {/* <div className='loginr'>
        <div className="rightlogin">
          <img src="images/blog/5d6be775a3a53e0896ff5931_Teamwork.svg" alt="" />
        </div>
        <div className="leftlogin">
          <h1> REGISTER</h1>
          <div className='container-login'>
            <div className='top-login'>
              <i class='fab fa-google'></i>
              <i class='fab fa-facebook-square'></i>
              <i class='fab fa-linkedin'></i>
              <i class='fab fa-twitter-square'></i>
              <i class='fab fa-apple'></i>
            </div>
            <p className='divider'><span className='spain'>Or</span></p>
            <form onSubmit={handleSubmit}>
              <label>Username</label>
              <input name='username' placeholder='Enter your email' onChange={handleChange} />
              <label>E-mail</label>
              <input name='email' placeholder='Enter your email' onChange={handleChange} />
              <label>Password</label>
              <input type='password' name='password' placeholder='Enter your password' onChange={handleChange} />
              <label>Country</label>
              <input type='text' placeholder='Country' name='country' onChange={handleChange}/>
              <label>Institution/Establishment Name</label>
              <input type='text' placeholder='Institution/Establishment Name' name='institution'onChange={handleChange} />
              <label>Is your Institution Accredited</label>
              <input type='text' placeholder='Is your Institution Accredited' name='accredited'onChange={handleChange} />
              <label>Your Accrediting Organisation Name</label>
              <input type='text' placeholder='Your Accrediting Organisation Name' name='organisation'onChange={handleChange} />
              <button type='submit' className='btn-login'>Register</button>
            </form>
          </div>


        </div>
      </div> */}
      <div className='container'>
        <div className="form-container">
          <form onSubmit={handleRegister}>
            <Box display='flex' flexDirection={'column'} Width={900} alignItems='center'>
              <Typography variant="h3" component="h2" sx={{ fontSize: { xs: 30 }, mt: 5 }}>
                Request a demo
              </Typography>
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} name='username' label="username:" variant="outlined" onChange={(e) => setUsername(e.target.value)} />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 2, mb: 1 }} name='email' label="E-mail" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} name='password' type='password' label="Password:" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} name='country' label="Country" variant="outlined" />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} name='institution' label="Institution/Establishment Name" variant="outlined" />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} name='accredited' label="Is your Institution Accredited" variant="outlined" />
              <TextField id="outlined-basic" className='access' sx={{ width: { xs: 350, sm: 400, md: 500, lg: 600, xl: 700 }, mt: 5, mb: 1 }} name='organisation' label="Your Accrediting Organisation Name" variant="outlined" />
            </Box>
            <button type='submit' className='btn-logint'>Register</button>
          </form>
          {/* <a href='https://api.citrine.cloud/api/connect/google'
          >
            Login via Google
          </a>

          <button onClick={logout}>Logout</button> */}
        </div>
      </div>
    </>
  )
}
