import React from 'react';
import Loading from '../../components/loading/Loading';
import useFetch from '../../hooks/useFetch';
import './HeadLogo.css'
export default function HeadLogo() {
  //const { estate, error, loading } = useFetch('https://www.citrine.cloud/api/logoheader')
  //const { estate, error, loading } = useFetch('http://localhost:1337/api/logoheader')
  const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/logoheader')

  if (loading) return <Loading />;
  if (error) return <p> Error :( </p>;

  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <h1>{estate.attributes.logo}</h1>
            <span>{estate.attributes.subtitlelogo}</span>
          </div>

          <div className='social'>
            <i className='fab fa-twitter icon'></i>   
            <i className='fab fa-linkedin icon'></i>
            <i className='fab fa-google icon'  onClick={() =>
            (window.location.href = 'https://api.citrine.cloud/api/connect/google')}></i>
          </div>
        </div>
      </section>
    </>
  )
}
