import { Button, FormControl, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import './Formpricing.css';
export default function FormPricing() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [university, setUniversity] = useState('')

    const url = 'https://api.citrine.cloud/api/pricings'

    const handleForm = async (e) => {
        e.preventDefault()
        axios.post(url,
            {
                "data": {
                    name: name,
                    email: email,
                    university: university
                }
            }
        )
            .then(response => {
                console.log(response);
            });

            
    }



    return (
        <div className='container'>
            <div className="allform">
                <form onSubmit={handleForm}>
                    <FormControl className='formcontrole'>
                        <TextField id="outlined-basic" label="name" variant="outlined" margin="dense" onChange={(e) => setName(e.target.value)} value={name} />
                        <TextField id="outlined-basic" label="E-mail" variant="outlined" margin="dense" onChange={(e) => setEmail(e.target.value)} value={email} />
                        <TextField id="outlined-basic" label="University Size" variant="outlined" type="number" margin="dense" onChange={(e) => setUniversity(e.target.value)} value={university} />
                        <Button variant="text" type='submit'>submit</Button>
                    </FormControl>
                </form>
                <div className="rightform">
                    <img src="/images/Forms-cuate.png" alt="" style={{ width: '500px' }} />
                </div>
            </div>
        </div>
    )
}
