import React, { useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import Nav from '../singleComponents/nav/Nav'
import CitrineBlog from './citrineComponents/CitrineBlog/CitrineBlog'
import Community from './citrineComponents/Community/Community'
import Edocs from './citrineComponents/edocs/Edocs'
import Eshop from './citrineComponents/eShop/Eshop'
import Events from './citrineComponents/eventes/Events'
import Partners from './citrineComponents/Partners/Partners'
import Podcast from './citrineComponents/Podcast/Podcast'
import Recognition from './citrineComponents/Recognition/Recognition'
import Training from './citrineComponents/Training/Training'
import Webinairs from './citrineComponents/Webinars/Webinairs'
import './CitrineHub.css'
export default function CitrineHub() {
    const { estate } = useFetch('https://api.citrine.cloud/api/citrine-event?populate=*')
    const { estate: edocs } = useFetch('https://api.citrine.cloud/api/edoc?populate=*')
    const { estate: community } = useFetch('https://api.citrine.cloud/api/community?populate=*')
    const { estate: eshop } = useFetch('https://api.citrine.cloud/api/e-shop?populate=*')
    const { estate: partners } = useFetch('https://api.citrine.cloud/api/partner?populate=*')
    const { estate: podcast } = useFetch('https://api.citrine.cloud/api/podcast?populate=*')
    const { estate: recognition } = useFetch('https://api.citrine.cloud/api/recognetion?populate=*')
    const { estate: webinairs } = useFetch('https://api.citrine.cloud/api/webinar?populate=*')
    const { estate: training } = useFetch('https://api.citrine.cloud/api/training?populate=*')
    const { estate: citrineBlog } = useFetch('https://api.citrine.cloud/api/blog?populate=*')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Nav />
            {/* <CitrineHubHeader title='Citrine Hub' /> */}
            <div className="citrinehub-colection">
                <Events estate={estate} />
                <Edocs edocs={edocs} />
                <Community community={community} />
                <Eshop eshop={eshop} />
                <Partners partners={partners} />
                <Podcast podcast={podcast} />
                <Recognition recognition={recognition} />
                <Webinairs webinairs={webinairs} />
                <Training training={training} />
                <CitrineBlog citrineBlog={citrineBlog} />
            </div>
        </>
    )
}
