import React from 'react';
import useFetch from '../../hooks/useFetch.js';
export default function Mainthree() {
    //const { estate, error, loading } = useFetch('http://localhost:1337/api/mainthrees?populate=*')
    //const { estate, error, loading } = useFetch('http://localhost:1337/api/mainthrees?populate=*')
    const { estate, error, loading } = useFetch('https://api.citrine.cloud/api/mainthrees?populate=*')

    if (loading) return <p> Loading... </p>;
    if (error) return <p> Error :( </p>;


    return (

        <div className='items'>
            {estate.map((val) => {
                return (
                    <div className='item flexSB' key={val.id}>
                        <div className='img' style={{ paddingRight: '15px' }}>
                            {val.attributes.image.data?.map((img) => (
                                <img src={`https://api.citrine.cloud${img.attributes.url}`} alt='' />
                            ))}
                        </div>
                        <div className='text'>
                            <h2>{val.attributes.title}</h2>
                            <p className='text-para'>{val.attributes.description}</p>
                            <a className='btn-links' href={val.attributes.link}>{val.attributes.linkname}</a>
                        </div>
                    </div>
                    
                )
            })}
        </div>

    )
}
