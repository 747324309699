import React from 'react'
import './aboutPages.css'
export default function AboutSectionOne({sectionOne}) {
    const uri = sectionOne.attributes?.image?.data?.map((img)=>{
      return  img.attributes.url
    });
    return (
        <div className='container'>
            <div className="aboutonecontent">
                <div className="about-on-content">
                    <h2 className="about-title-one">{sectionOne.attributes?.title}</h2>
                    <p className='about-decription-one'>{sectionOne.attributes?.description}</p>
                </div>
                <div className="about-one-img">
                    <img src={`https://api.citrine.cloud${uri}`} alt="" />
                </div>
            </div>
        </div>
    )
}
