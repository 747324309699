import React, { useEffect } from 'react';
import Typebot from "typebot-js";
export default function Chatbot() {
    useEffect(() => {
        var typebotCommands = Typebot.initBubble({
            url: "https://viewer.typebot.io/digital-product-payment-copy-rcklv3v",
            button: { color: "#ffb805" },
        });
    }, []);

    return <></>;


}
